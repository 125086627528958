import React from "react"
import * as ROUTES from "../../../constants/routes"
import { NavLink } from "react-router-dom"
import ResourcesLink from "./Links/Resources"

const Linker = () => (
    <nav>
        <ul className="flex text-white font-helvetica tracking-wide text-base xs:text-xs sm:text-sm md:text-sm justify-between xs:px-6">
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><NavLink to={ROUTES.HOME.link} exact={true} activeClassName="cursor-pointer text-orange-400"> {ROUTES.HOME.name}</NavLink></li>
            <ResourcesLink />
            {/*
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><NavLink to={ROUTES.BLOG.link} activeClassName="cursor-pointer text-orange-400"> {ROUTES.BLOG.name}</NavLink></li>
            */}
    {/*<li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><NavLink to={ROUTES.FORUM.link} activeClassName="cursor-pointer text-orange-400" >{ROUTES.FORUM.name}</NavLink></li>*/}
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><a href="https://www.reddit.com/r/YourFirstYearTeaching/">Forum</a></li>
    {/*<li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><NavLink to={ROUTES.BUY.link} activeClassName="cursor-pointer text-orange-400">{ROUTES.BUY.name}</NavLink></li>*/}
            <li className="xl:mr-6 sm:mr-8 md:mr-8 mr-4"><a href="https://smile.amazon.com/gp/product/173581072X">Buy</a></li>
        </ul>
    </nav>
)
export default Linker
