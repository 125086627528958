import React from "react"
import { Switch, Route } from "react-router-dom"
import Home, {
    ResourcesPage,
    ExampleWebsitePage,
    ExampleGradingSpreadsheetsPage,
    ExampleScriptsPage,
    OnlineResourcesPage,
    BlogPage,
    ForumPage,
    LoginPage,
    BuyPage
} from "../../../pages"

const Switcher = () => (
    <Switch>
        <Route path="/resources/grading-spreadsheets">
            <ExampleGradingSpreadsheetsPage />
        </Route>
        <Route path="/resources/scripts">
            <ExampleScriptsPage />
        </Route>
        <Route path="/resources/online-resources">
            <OnlineResourcesPage />
        </Route>
        <Route path="/resources/example-website">
            <ExampleWebsitePage />
        </Route>
        <Route path="/resources">
            <ResourcesPage />
        </Route>
        {/*<Route path="/products">
            <BlogPage />
            </Route>*/}
        <Route path="/forum">
            <ForumPage />
        </Route>
        {/*<Route path="/login">
            <LoginPage />
            </Route>*/}
    
    {/*<Route path="/buy">
            <BuyPage />
        </Route>*/}
    <Route path="/buy"  
        component={() => {
        global.window && (global.window.location.href = 'https://smile.amazon.com/gp/product/173581072X');
        return null;
    }}/>
        
        <Route path="/">
            <Home />
        </Route>
    </Switch>)

export default Switcher
