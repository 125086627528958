import React from 'react';
import Switcher from './Switcher';
import Linker from "./Linker"

const Navigation = () => (
    <div>
        <Linker />
    </div>)

export default Navigation
export { Switcher }