import React from "react";
import '../../assets/custom.css';
const ExampleScripts = () => {
    return(
        <div className="allpadding">
            Here is an example TinyFeedback Python script that can be used to email a random set of students in your class. More examples will be added soon!
            <p />
            <ul className="bullets">
                <li><a className="linkText" href="/scripts/tiny-feedback.py">tiny-feedback.py</a></li>
            </ul>

             
        </div>
        );
}
export default ExampleScripts
