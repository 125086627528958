export const items = [
    {
        categoryName: "EXAMPLE WEBSITE",
        links: [{
            name: "Example Website",
            route: "example-website"
        },]
    },
    {
        categoryName: "GRADING SPREADSHEET",
        links: [{
            name: "Example Grading Spreadsheets",
            route: "grading-spreadsheets"
        },]
    },
    {
        categoryName: "SCRIPTS",
        links: [{
            name: "Example Scripts",
            route: "scripts"
        }]
    },
    {
        categoryName: "ONLINE RESOURCES",
        links: [{
            name: "Online Resources",
            route: "online-resources"
        }]
    }
]
