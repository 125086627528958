import React from "react";
import '../../assets/custom.css';
import {NavLink} from 'react-router-dom';
const Resources = () => {
    return (
        <div className="allpadding">
            <NavLink className="linkText" to="/resources/example-website">Example Website</NavLink>
            <p />
        <NavLink className="linkText" to="/resources/grading-spreadsheets">Sample Grading Spreadsheets</NavLink>
            <p />
        <NavLink className="linkText" to="/resources/scripts">Sample Scripts</NavLink>
            <p />
        <NavLink className="linkText" to="/resources/online-resources">Online Resources</NavLink>
        </div>
    );
}
export default Resources
