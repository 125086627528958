import React from "react";
import '../../assets/custom.css';
const OnlineResources = () => {
    return(
        <div className="allpadding">
            The following list of online resources is from Chapter 10 of <i>Your First Year Teaching Computer Science</i>. More sites will be added as we discover them!
            <ul className="bullets">
                <li><a className="linkText" href="https://www.google.com/search?q=algorithm+animations">Google search for algorithm animations</a></li>
                <li><a className="linkText" href="https://support.google.com/websearch/answer/2466433">How to refine your Google Search</a></li>
                <li><a className="linkText" href="http://csteachingtips.org">CS Teaching Tips</a></li>
                <li><a className="linkText" href="https://cseducators.stackexchange.com">CS Educators StackExchange</a></li>
                <li><a className="linkText" href="ttps://sigcse.org/sigcse/membership/mailing-lists.html">SIGCSE Mailing List</a></li>
                <li><a className="linkText" href="https://listserv.acm.org/SCRIPTS/WA-ACMLPX.CGI?A0=SIGCSE-MEMBERS">SIGCSE Mailing List Archive</a></li>
                <li><a className="linkText" href="https://www.quora.com/topic/Computer-Science">Quora Computer Science Space</a></li>
                <li><a className="linkText" href="https://en.wikipedia.org/wiki/Comparison_of_online_source_code_playgrounds">Comparison of Online Source Code Playgrounds (Wikipedia)</a></li>
                <li><a className="linkText" href="https://www.toptal.com/developers/sorting-algorithms">Sorting Algorithms</a></li>
                <li><a className="linkText" href="https://visualgo.net/en">Data Structure Visualizations</a></li>
                <li><a className="linkText" href="https://www.cs.usfca.edu/~galles/visualization/">More Data Structure Visualizations</a></li>
                <li><a className="linkText" href="http://pythontutor.com">Visualize Code Execution (use to be just Python, now has many languages)</a></li>
                <li><a className="linkText" href="https://codestepbystep.com">Code Step By Step practice problems</a></li>
                <li><a className="linkText" href="https://codingbat.com/java">CodingBat practice problems (Java and Python)</a></li>
                <li><a className="linkText" href="https://www.hackerearth.com/practice/algorithms">HackerEarth practice problems</a></li>
                <li><a className="linkText" href="http://rosettacode.org/wiki/Rosetta_Code">Rosetta Code example code</a></li>
                <li><a className="linkText" href="https://llimllib.github.io/bloomfilter-tutorial/">Bloom Filter tutorial</a></li>
                <li><a className="linkText" href="Skiplist animation">https://people.ok.ubc.ca/ylucet/DS/SkipList.html</a></li>
                <li><a className="linkText" href="https://godbolt.org">Compiler Explorer assembly / C / C++ compilation</a></li>
                <li><a className="linkText" href="https://www.h-schmidt.net/FloatConverter/IEEE754.html">Floating Point converter</a></li>
                <li><a className="linkText" href="https://babbage.cs.qc.cuny.edu/IEEE-754.old/Decimal.html">A different floating point converter</a></li>
                <li><a className="linkText" href="http://stanford.edu/~cgregg/107-Reader/float/convert.html">Another floating point converter</a></li>
                <li><a className="linkText" href="https://www.onlinegdb.com">Online gdb</a></li>
                <li><a className="linkText" href="https://github.com/yangshun/tech-interview-handbook">Technical Interview Handbook</a></li>
            </ul>
        </div>
        );
}
export default OnlineResources
