import React from "react";
import '../../assets/custom.css';
import {NavLink} from 'react-router-dom';
const ExampleGradingSpreadsheets = () => {
    return(
        <div className="allpadding">
            Here is an example grading spreadsheet in Microsoft Excel. More examples will be added soon!
            <p />
            <ul className="bullets">
                <li><a className="linkText" href="/grading-spreadsheets/CS2Grades.xlsx">CS2Grades.xlsx</a></li>
            </ul>

        </div>
        );
}
export default ExampleGradingSpreadsheets
