import ForumPage from "./Forum"
import HomePage from "./Home"
import ResourcesPage from "./Resources"
import BlogPage from "./Blog"
import ExampleWebsitePage from "./ExampleWebsite"
import ExampleGradingSpreadsheetsPage from "./ExampleGradingSpreadsheets"
import ExampleScriptsPage from "./ExampleScripts"
import OnlineResourcesPage from "./OnlineResources"
import LoginPage from "./Login"
import BuyPage from "./Buy"

export default HomePage;
export { ResourcesPage, BlogPage, ForumPage, LoginPage, BuyPage, ExampleWebsitePage, ExampleGradingSpreadsheetsPage, ExampleScriptsPage, OnlineResourcesPage }
