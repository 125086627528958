import React from "react";
import '../../assets/custom.css';
const ExampleWebsite = () => {
    const github_site = "https://github.com/yourfirstyearteaching/example_cs2_website";
    return(
        <div className="allpadding">
            The following example website is from Chapter 6 of <i>Your First Year Teaching Computer Science</i>. You can download the source for the website here: <a className="linkText" href={github_site}>{github_site}</a>.
            <p />
            The example site can be seen <a className="linkText" href="http://yourfirstyearteaching.com/cs/example_cs2_website/">here</a>.
        </div>
        );
}
export default ExampleWebsite
