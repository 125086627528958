import '../../assets/custom.css';
import React, { PureComponent} from "react";
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Statement, { GetStartedButton } from "../../components/features/Statement"
import PostListContainer from '../../components/features/Blog/PostListContainer'
import PostContainer from '../../components/features/Blog/PostContainer';
import store from '../../components/features/redux/store';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    NavLink,
} from 'react-router-dom';

const Home = () => (
    <div className="flexcontainer">
        <div className="mt-10 flex flex-col items-center pb-24">
            <Statement />
        </div>
        <div className="filler" />
        <div className="somespace">
            <div className="blog">
                <span className="welcome-header">Welcome to <i>Your First Year Teaching!</i></span><p />
                <div className="med-text">
                    <p>
                        This site is the companion website to the book, <i>Your First Year Teaching Computer Science: A Practical Guide to Success for New Computer Science Teachers</i>, by Chris Gregg. But, it does not need to be strictly about teaching computer science, and as we add resources, it will hopefully be a place all new teachers can come to for help with their foray into the world of teaching.
                    </p>
                    <ul className="bullets">
                        <li>In the <NavLink className="linkText" to="/resources">Resources</NavLink> section, you will find many resources for new computer science teachers, as well as all of the example documents and scripts from the book.</li>
                        <li>The subreddit <a className="linkText" href="https://reddit.com/r/YourFirstYearTeaching">Forum</a> is another great resource to talk about teaching, particularly if you are a new teacher.</li>
                    </ul>
                </div>
            </div>
            {/*
            <Provider store={store}>
                <MuiThemeProvider>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={PostListContainer} />
                            <Route path="/posts/:id/:slug" component={PostContainer} />
                        </Switch>
                    </Router>
                </MuiThemeProvider>
            </Provider>
            */}
        </div>
    </div>
)
export default Home
